import React, { FC, useCallback, useState } from 'react';
import {
  useJsApiLoader,
  GoogleMap as Map,
  Marker,
} from '@react-google-maps/api';
import { GOOGLE_MAP } from '@config';
import logoIcon from '@assets/images/logo.png';
import { graphql, useStaticQuery } from 'gatsby';

const GoogleMap: FC<Props> = ({ className, ...props }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY as string,
  });
  const {
    datoCmsSetting,
  }: {
    datoCmsSetting: {
      coords: {
        latitude: number;
        longitude: number;
      };
    };
  } = useStaticQuery(query);

  const [map, setMap] = useState(null);

  const win = typeof window !== 'undefined' && (window as any);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => setMap(null), []);

  return isLoaded ? (
    <Map
      center={{
        lat: datoCmsSetting.coords.latitude,
        lng: datoCmsSetting.coords.longitude,
      }}
      zoom={GOOGLE_MAP.zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      mapContainerClassName={className}
      options={{
        zoomControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
      }}
      {...props}
    >
      <Marker
        icon={{
          url: logoIcon,
          anchor: new win.google.maps.Point(30, 60),
          scaledSize: new win.google.maps.Size(60, 60),
        }}
        position={{
          lat: datoCmsSetting.coords.latitude,
          lng: datoCmsSetting.coords.longitude,
        }}
      />
    </Map>
  ) : (
    <></>
  );
};

const query = graphql`
  {
    datoCmsSetting {
      coords {
        latitude
        longitude
      }
    }
  }
`;

interface Props {
  className?: string;
}

export default React.memo(GoogleMap);
