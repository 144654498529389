import React, { FC, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { BasicTemplate } from '@templates/BasicTemplate';
import { graphql } from 'gatsby';
import {
  ChildImageSharp,
  ChildImageSharpImage,
} from '../types/childImageSharpImage';
import HeroSlider from '@components/molecules/HeroSlider/HeroSlider';
import { rgba } from 'polished';
import Button from '@components/atoms/Button/Button';
import patternImg from '@assets/images/pattern.jpg';
import patternImg2x from '@assets/images/pattern@2x.jpg';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import FeatureBlock from '@components/atoms/FeatureBlock/FeatureBlock';
import treeIcon from '@assets/icons/tree';
import parkingIcon from '@assets/icons/parking';
import wwwIcon from '@assets/icons/www';
import floorIcon from '@assets/icons/floor';
import happyPeopleIcon from '@assets/icons/happyPeople';
import streetIcon from '@assets/icons/street';
import Gallery from '@components/molecules/Gallery/Gallery';
import GoogleMap from '@components/molecules/GoogleMap/GoogleMap';
import Houses from '@components/organisms/Houses/Houses';
import { scrollTo } from '@utils/scrollTo';
import Icon from '@iconify/react';
import plantIcon from '@assets/icons/plant';
import leafIcon from '@assets/icons/leaf';
import { useLocation } from 'react-use';

const StyledHeader = styled.div`
  position: relative;
  max-width: 100%;
  overflow: hidden;
`;

const StyledBox = styled.div`
  background: ${({ theme }) => rgba(theme.foreground, 0.5)};
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  z-index: 50;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  color: ${({ theme }) => theme.background};

  @media (min-width: 1025px) {
    background: transparent;
  }
`;

const StyledHeaderHeadline = styled.h1`
  margin: 0;
  font-size: 4.5rem;
  text-align: center;

  @media (min-width: 1025px) {
    font-size: 11.5rem;
    line-height: 1.1;
  }
`;

const StyledHeaderParagraph = styled.p`
  font-size: 2rem;
  margin: 0;
  text-align: center;

  @media (min-width: 1025px) {
    font-size: 3.4rem;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 5%;
  justify-content: center;
  margin-right: -25px;
  width: calc(100% - 25px);
  margin-top: 10px;

  @media (min-width: 1025px) {
    margin-top: 40px;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
  margin-right: 20px;

  @media (min-width: 1025px) {
    margin-right: 60px;
    margin-top: 0px;
  }
`;

const StyledWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1025px) {
    margin-top: 140px;
  }
`;

const StyledAboutEstateAndFeaturesWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  max-width: 750px;

  @media (min-width: 1025px) {
    display: flex;
    padding-right: 0;
    margin-bottom: 160px;
    max-width: 1905px;
    position: relative;
  }

  @media (min-width: 1503px) {
    padding-right: 7%;
  }

  @media (min-width: 1920px) {
    padding-right: 133px;
  }
`;

const StyledFirstPlant = styled(Icon)`
  display: none;
  position: absolute;
  font-size: 30rem;
  z-index: -1;

  @media (min-width: 1248px) {
    display: block;
    left: 5%;
    bottom: -30%;
  }

  @media (min-width: 1503px) {
    bottom: -20%;
  }

  @media (min-width: 1736px) {
    left: 5%;
    bottom: -10%;
    font-size: 45rem;
  }
`;

const StyledAboutEstate = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 5% calc(0.25 * 50vw + 30px);
  background: url(${patternImg});
  color: ${({ theme }) => theme.background};
  position: relative;
  margin-bottom: calc(0.75 * 50vw);

  @media (min-width: 624px) {
    margin-bottom: 234px;
  }

  @media (min-width: 1025px) {
    width: 42%;
    align-items: flex-start;
    padding: 45px 4% 100px;
    margin-top: 40px;
    margin-right: 40px;
    margin-bottom: 0;
    background: url(${patternImg2x});
  }

  @media (min-width: 1503px) {
    width: 50%;
    padding-left: 7%;
  }

  @media (min-width: 1248px) {
    margin-bottom: calc(0.25 * 30vw);
  }

  @media (min-width: 1503px) {
    margin-bottom: calc(0.3 * 30vw);
  }

  @media (min-width: 1736px) {
    width: 62%;
    margin-bottom: calc(0.74 * 30vw);
  }

  @media (min-width: 1823px) {
    margin-bottom: calc(0.83 * 30vw);
  }

  @media (min-width: 1920px) {
    margin-bottom: 478px;
  }
`;

const StyledAboutEstateHeadline = styled.h2`
  margin: 0 0 25px;

  @media (min-width: 1025px) {
    font-size: 4.5rem;
    margin-bottom: 65px;
  }
`;

const StyledAboutEstateParagraphsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1025px) {
    max-width: unset;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media (min-width: 1736px) {
    flex-direction: row;
  }
`;

const StyledAboutEstateParagraph = styled.p`
  margin: 0 0 25px;
  max-width: 530px;

  @media (min-width: 1025px) {
    max-width: 390px;
    line-height: 2;

    :first-of-type {
      max-width: 425px;
      margin-right: 10px;
    }

    :nth-of-type(2) {
      max-width: 388px;
    }

    :last-of-type {
      max-width: 340px;
    }
  }

  @media (min-width: 1736px) {
    :first-of-type {
      order: 0;
    }

    :nth-of-type(2) {
      order: 2;
    }

    :last-of-type {
      order: 1;
    }
  }

  @media (min-width: 1823px) {
    order: 0 !important;
  }
`;

const StyledAboutEstateGatsbyImage = styled(GatsbyImage)`
  position: absolute !important;
  top: 100%;
  transform: translateY(-30%);
  width: 90%;
  height: 50vw;
  max-height: 314px;
  max-width: 576px;

  @media (min-width: 1025px) {
    right: -140%;
    bottom: 6%;
    top: unset;
    width: 55vw;
    height: 30vw;
    transform: unset;
    max-width: 1056px;
    max-height: 576px;
  }

  @media (min-width: 1099px) {
    bottom: 1%;
  }

  @media (min-width: 1164px) {
    right: -130%;
    bottom: -1%;
  }

  @media (min-width: 1248px) {
    right: -125%;
    bottom: -15%;
  }

  @media (min-width: 1503px) {
    right: -95%;
    bottom: -20%;
  }

  @media (min-width: 1736px) {
    bottom: -50%;
    right: -64%;
  }

  @media (min-width: 1823px) {
    bottom: -73%;
  }
`;

const StyledFeaturesWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  width: 90%;
  margin: 40px auto 0;
  padding-top: 40px;
  max-width: 530px;

  @media (min-width: 346px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1025px) {
    max-width: unset;
    margin: 0;
    padding-top: 0;
    width: 58%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (min-width: 1503px) {
    width: 50%;
  }

  @media (min-width: 1736px) {
    width: 40%;
  }
`;

const StyledFeature = styled(FeatureBlock)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.wash};

  :last-of-type {
    border-bottom: 0;
  }

  @media (min-width: 346px) {
    :nth-of-type(odd) {
      border-right: 1px solid ${({ theme }) => theme.wash};
      border-bottom: 1px solid ${({ theme }) => theme.wash};
    }

    :nth-of-type(even) {
      border-bottom: 1px solid ${({ theme }) => theme.wash};
    }

    :nth-of-type(5),
    :nth-of-type(6) {
      border-bottom: 0;
    }
  }

  @media (min-width: 1025px) {
    height: 270px;
    width: 30%;

    :nth-of-type(1),
    :nth-of-type(2) {
      border-right: 1px solid ${({ theme }) => theme.wash};
      border-bottom: 1px solid ${({ theme }) => theme.wash};
    }

    :nth-of-type(2) {
      width: 36%;
    }

    :nth-of-type(3) {
      border-bottom: 1px solid ${({ theme }) => theme.wash};
      border-right: 0;
    }

    :nth-of-type(4),
    :nth-of-type(5) {
      border-right: 1px solid ${({ theme }) => theme.wash};
      border-bottom: 0;
    }

    :nth-of-type(5) {
      width: 36%;
    }

    :nth-of-type(6) {
      border: 0;
    }
  }
`;

const StyledSection = styled.section<{ $withPatternBackground?: boolean }>`
  width: 100%;
  padding: 0 5%;
  margin: 0 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${({ $withPatternBackground }) =>
    $withPatternBackground &&
    css`
      margin-bottom: calc(0.25 * 45vw + 80px);
    `};

  @media (min-width: 1025px) {
    margin: 0 0 180px;

    :last-of-type {
      margin: 160px 0;
    }
  }
`;

const StyledInnerSectionWrapper = styled.div<{
  $location?: boolean;
  $gallery?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  ${({ $location }) =>
    $location &&
    css`
      max-width: 1650px;
    `}

  ${({ $gallery }) =>
    $gallery &&
    css`
      max-width: 1400px;
    `}
`;

const StyledHeadline = styled.h2<{ $smallerMargin?: boolean }>`
  font-size: 2.4rem;
  margin: 0 0 20px;
  text-align: center;

  @media (min-width: 1025px) {
    font-size: 4.5rem;
    margin-bottom: 70px;

    ${({ $smallerMargin }) =>
      $smallerMargin &&
      css`
        margin-bottom: 20px;
      `}
  }
`;

const StyledParagraph = styled.p`
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
  max-width: 500px;

  @media (min-width: 1025px) {
    margin-bottom: 60px;
  }
`;

const StyledGoogleMap = styled(GoogleMap)`
  width: 100%;
  height: 60vw;
  margin-top: 10px;
  max-width: 1650px;

  @media (min-width: 1025px) {
    height: 680px;
  }
`;

const StyledGoogleMapPatternBackground = styled.div`
  background: url(${patternImg});
  width: 100%;
  height: 45vw;
  position: absolute;
  z-index: -1;
  bottom: 0;
  transform: translateY(25%);

  @media (min-width: 1025px) {
    height: 550px;
    background: url(${patternImg2x});
  }
`;

const StyledSecondPlant = styled(Icon)`
  position: absolute;
  font-size: 35rem;
  right: 5%;
  z-index: -1;
  transform: translateY(-25%) rotateY(180deg) !important;
  display: none;

  @media (min-width: 1025px) {
    display: block;
  }
`;

const StyledLeaf = styled(Icon)`
  position: absolute;
  z-index: -1;
  transform: translateY(-40%) !important;
  left: 10%;
  font-size: 8rem;

  @media (min-width: 1024px) {
    transform: translateY(-30%) !important;
    left: 10%;
    font-size: 25rem;
  }
`;

const StyledThirdPlant = styled(Icon)`
  position: absolute;
  right: 7%;
  z-index: -1;
  transform: translateY(-30%) !important;
  font-size: 12rem;

  @media (min-width: 1025px) {
    right: 0;
    font-size: 40rem;
    transform: translateY(-40%) !important;
  }
`;

const StyledGallery = styled(Gallery)`
  margin: 20px 0 0;
`;

const StyledHouses = styled(Houses)`
  width: 90%;
`;

const Index: FC<Props> = ({ data }) => {
  const { state } = useLocation();

  useEffect(() => {
    if (state?.prevPath?.includes('lokal')) {
      scrollTo('#houses');
    }
  }, [state?.prevPath]);

  return (
    <BasicTemplate
      header={() => (
        <StyledHeader>
          <HeroSlider images={data.hero.nodes} />
          <StyledBox>
            <StyledHeaderHeadline
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              Oleńki Park
            </StyledHeaderHeadline>
            <StyledHeaderParagraph
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-duration="1000"
            >
              Osiedle blisko natury
            </StyledHeaderParagraph>
            <StyledButtonsWrapper data-aos="zoom-out" data-aos-duration="1000">
              <StyledButton onClick={() => scrollTo('#about_estate')}>
                Sprawdź ofertę
              </StyledButton>
              <StyledButton onClick={() => scrollTo('#contact')}>
                Skontaktuj się
              </StyledButton>
            </StyledButtonsWrapper>
          </StyledBox>
        </StyledHeader>
      )}
    >
      <StyledWrapper>
        <StyledAboutEstateAndFeaturesWrapper>
          <StyledAboutEstate id="about_estate">
            <StyledAboutEstateHeadline data-aos="fade-up">
              O osiedlu
            </StyledAboutEstateHeadline>
            <StyledAboutEstateParagraphsWrapper>
              <StyledAboutEstateParagraph data-aos="fade-up">
                <b>
                  <u>Oleńki Park</u>
                </b>{' '}
                to zamknięte osiedle składające się z 4 domów bliźniaczych, w
                których znajduje się 8 lokali - <b>niespotykana</b> dotąd w
                naszym mieście
                <b> inwestycja</b>, która jest tak blisko natury. Osiedle otacza
                las, zapewniający ciszę i <b>bliskość natury.</b>
              </StyledAboutEstateParagraph>

              <StyledAboutEstateParagraph data-aos="fade-up">
                <b>Osiedle</b> zostało stworzone <u>z myślą o rodzinach</u>,
                Które cenią sobie spokój oraz <b>doskonałą lokalizację</b> na
                uboczu. Funkcjonalność pomieszczeń oraz efektywność Jej
                wykorzystania została bardzo dobrze przemyślana, jeszcze{' '}
                <u>przed rozpoczęciem prac.</u>
              </StyledAboutEstateParagraph>

              <StyledAboutEstateParagraph data-aos="fade-up">
                <b>Do Państwa dyspozycji</b> oddajemy 4 pomieszczenia + salon z
                kuchnią w 112,5 m². o lokalu są przynależne 2 miejsca postojowe
                na zewnątrz.
              </StyledAboutEstateParagraph>
            </StyledAboutEstateParagraphsWrapper>

            <StyledAboutEstateGatsbyImage
              image={getImage(data.aboutEstate as any) as IGatsbyImageData}
              alt="Oleńki Park"
            />
          </StyledAboutEstate>

          <StyledFeaturesWrapper>
            <StyledFeature icon={treeIcon} data-aos="fade-up">
              Zielone osiedle <span>w sąsiedztwie lasu</span>
            </StyledFeature>

            <StyledFeature
              icon={floorIcon}
              data-aos="fade-up"
              data-aos-delay="300"
            >
              Na parterach <span>ogrzewanie podłogowe</span>
            </StyledFeature>

            <StyledFeature
              icon={parkingIcon}
              data-aos="fade-up"
              data-aos-delay="600"
            >
              Dla każdego lokalu <span>2 miejsca parkingowe</span>
            </StyledFeature>

            <StyledFeature icon={happyPeopleIcon} data-aos="fade-up">
              Osiedle stworzone <span>z myślą o przestrzeni</span>
            </StyledFeature>

            <StyledFeature
              icon={wwwIcon}
              data-aos="fade-up"
              data-aos-delay="300"
            >
              Dla każdego lokalu <span>internet światłowodowy</span>
            </StyledFeature>

            <StyledFeature
              icon={streetIcon}
              data-aos="fade-up"
              data-aos-delay="600"
            >
              Wyjazd na drogę <span>ekspresową S8</span>
            </StyledFeature>
          </StyledFeaturesWrapper>

          <StyledFirstPlant icon={plantIcon} />
        </StyledAboutEstateAndFeaturesWrapper>

        <StyledSection>
          <StyledHeadline id="visualizations" data-aos="fade-up">
            Wizualizacje
          </StyledHeadline>

          <StyledGallery
            images={data.visualizations.nodes}
            lightboxImages={data.lightboxVisualizations.nodes}
            data-aos="fade-up"
          />
        </StyledSection>

        <StyledHouses image={data.overhead} />

        <StyledSection $withPatternBackground>
          <StyledInnerSectionWrapper $location>
            <StyledHeadline id="location" $smallerMargin data-aos="fade-up">
              Lokalizacja
            </StyledHeadline>
            <StyledParagraph data-aos="fade-up">
              Osiedle 4 domów bliźniaczych z 8 lokalami, znajdujących się na ul.
              Oleńki 23 w Kobyłce.
            </StyledParagraph>

            <StyledGoogleMap />
            <StyledSecondPlant icon={plantIcon} />
            <StyledLeaf icon={leafIcon} />
          </StyledInnerSectionWrapper>
          <StyledGoogleMapPatternBackground />
        </StyledSection>

        <StyledSection>
          <StyledInnerSectionWrapper $gallery>
            <StyledHeadline id="gallery" data-aos="fade-up">
              Galeria zdjęć z budowy
            </StyledHeadline>
            <StyledGallery
              images={data.construction.nodes}
              lightboxImages={data.lightboxConstruction.nodes}
              data-aos="fade-up"
            />
            <StyledThirdPlant icon={plantIcon} />
          </StyledInnerSectionWrapper>
        </StyledSection>
      </StyledWrapper>
    </BasicTemplate>
  );
};

interface Props {
  data: {
    overhead: ChildImageSharpImage;
    aboutEstate: ChildImageSharpImage;
    visualizations: { nodes: ChildImageSharp[] };
    lightboxVisualizations: { nodes: ChildImageSharp[] };
    construction: { nodes: ChildImageSharp[] };
    lightboxConstruction: { nodes: ChildImageSharp[] };
    hero: { nodes: ChildImageSharp[] };

    // datoCmsGallery: {
    //   hero: ChildImageSharpImage[];
    //   build: ChildImageSharpImage[];
    //   visualizations: ChildImageSharpImage[];
    // };
  };
}

export const query = graphql`
  {
    overhead: file(name: { eq: "overhead" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }

    aboutEstate: file(name: { eq: "about_estate" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }

    hero: allFile(
      filter: { name: { regex: "/hero1|hero2|vis6|vis7/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: DOMINANT_COLOR
            quality: 80
          )
        }
      }
    }

    visualizations: allFile(
      filter: { name: { regex: "/vis/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: DOMINANT_COLOR
            quality: 80
          )
        }
      }
    }

    lightboxVisualizations: allFile(
      filter: { name: { regex: "/vis/" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, formats: JPG, layout: FIXED)
        }
      }
    }

    construction: allFile(
      filter: { name: { regex: "/construction_/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: DOMINANT_COLOR
            quality: 80
          )
        }
      }
    }

    lightboxConstruction: allFile(
      filter: { name: { regex: "/construction_/" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, formats: JPG, layout: FIXED)
        }
      }
    }

    # datoCmsGallery {
    #   hero {
    #     gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    #   }
    #   visualizations {
    #     gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    #   }
    #   build {
    #     gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    #   }
    # }
  }
`;

export default Index;
