import React, { FC } from 'react';
import styled from 'styled-components';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  ChildImageSharp,
  ChildImageSharpImage,
} from '../../../types/childImageSharpImage';
import 'react-image-lightbox/style.css';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { rgba } from 'polished';

SwiperCore.use([Pagination, Autoplay]);

const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;

  .swiper-container {
    z-index: 40;
  }

  .swiper-pagination {
    position: absolute;
    left: 50%;
    bottom: 20px;
    display: flex;
    transform: translateX(-50%);
    z-index: 90;

    @media (min-width: 1025px) {
      right: 5vw;
      bottom: 60px;
      left: unset;
      transform: unset;
    }
  }

  .swiper-pagination-bullet {
    width: 30px;
    height: 6px;
    border-radius: 99px;
    display: block;
    margin-right: 10px;
    background: ${({ theme }) => rgba(theme.background, 0.6)};
    cursor: pointer;

    :last-of-type {
      margin-right: 0;
    }

    &-active {
      background: ${({ theme }) => rgba(theme.primaryDarker, 1)};
    }

    @media (min-width: 1025px) {
      width: 55px;
      height: 7px;
      margin-right: 20px;
    }
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: 100vh;
`;

const StyledOverlay = styled.span`
  width: 100%;
  height: 100%;
  position: absolute;
  background: #000;
  opacity: 0.4;
  left: 0;
  top: 0;
  display: none;
  z-index: 40;

  @media (min-width: 1025px) {
    display: block;
  }
`;

const HeroSlider: FC<Props> = ({ className, images }) => (
  <StyledWrapper className={className}>
    <Swiper
      loop
      pagination={{ clickable: true }}
      autoplay={{ delay: 5000 }}
      speed={1000}
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <StyledGatsbyImage
            image={getImage(image as any) as IGatsbyImageData}
            alt=""
          />
        </SwiperSlide>
      ))}
      <StyledOverlay />
    </Swiper>
  </StyledWrapper>
);

interface Props {
  images: ChildImageSharp[];
  className?: string;
}

export default HeroSlider;
