import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import ArrowButton from '@components/atoms/ArrowButton/ArrowButton';
import SwiperCore, { Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import Lightbox from 'react-image-lightbox';
import {
  ChildImageSharp,
  ChildImageSharpImage,
} from '../../../types/childImageSharpImage';
import 'react-image-lightbox/style.css';

SwiperCore.use([Navigation, Thumbs]);

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  position: relative;
`;

const StyledSwipersWrapper = styled.div<{ $count: number }>`
  position: relative;
  margin-bottom: 7.5vw;

  .swiper-container:last-of-type {
    position: absolute;
    width: 80%;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);

    .swiper-wrapper {
      justify-content: ${({ $count }) =>
        $count > 3 ? 'flex-start' : 'center'};
    }

    .swiper-slide {
      width: 30%;
      filter: brightness(50%);
      max-width: 260px;
    }

    .swiper-slide-thumb-active {
      filter: brightness(100%);
    }

    @media (min-width: 1025px) {
      width: 65%;
      max-width: 844px;
      right: 0;
      transform: translateY(-50%);
      left: unset;

      .swiper-slide {
        width: 260px;
      }

      .swiper-wrapper {
        justify-content: flex-start;
      }
    }
  }

  @media (min-width: 1025px) {
    margin-bottom: 35px;
  }
`;

const StyledCounterWrapper = styled.div`
  display: none;
  width: 35%;
  justify-content: center;
  position: relative;

  @media (min-width: 1025px) {
    display: flex;
  }
`;

const StyledCounter = styled.div`
  font-size: 2.4rem;
  font-weight: ${({ theme }) => theme.fontBold};
  align-items: center;
  justify-content: space-between;
  width: 200px;
  position: relative;
  display: flex;
`;

const StyledCounterBar = styled.span<{ $count: number; $current: number }>`
  width: 100px;
  height: 4px;
  border-radius: 4px;
  background: ${({ theme }) => theme.wash};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ::before {
    content: '';
    position: absolute;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: ${({ theme }) => theme.primary};
    transform: ${({ $current, $count }) => `scaleX(${$current / $count})`};
    transition: transform 0.2s ease-in-out;
    transform-origin: top left;
  }
`;

const StyledMainGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: 53vw;
  max-height: 770px;
`;

const StyledThumbsGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: 15vw;
  max-height: 140px;
  cursor: pointer;
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    margin-right: 20px;
  }

  @media (min-width: 1025px) {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 53vw;
    margin-top: 0;
    transform: translateY(-100%);
    z-index: 98;
    width: 35%;
    height: 70px;

    button {
      color: ${({ theme }) => theme.background};
      margin-right: 100px;
    }

    button:last-of-type {
      margin-right: 0;
    }
  }

  @media (min-width: 1453px) {
    top: 770px;
  }
`;

const Gallery: FC<Props> = ({
  images,
  lightboxImages: lightboxImagesProp,
  className,
  ...props
}) => {
  const [isOpen, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(1);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);
  const [mainSwiper, setMainSwiper] = useState<SwiperCore | null>(null);

  const lightboxImages = lightboxImagesProp.map(
    (image) =>
      (
        ((image.childImageSharp as any).gatsbyImageData as ImageDataLike)
          .images as any
      ).fallback.src as string
  );

  const prevButton = useRef<HTMLButtonElement>(null);
  const nextButton = useRef<HTMLButtonElement>(null);

  const onSlideChange = (swiper: SwiperCore) => {
    setActiveIndex(swiper.realIndex);
  };

  const handleSlideClick = () => setOpen(true);

  return (
    <StyledWrapper className={className} {...props}>
      <StyledSwipersWrapper $count={images.length}>
        <Swiper
          thumbs={{ swiper: thumbsSwiper }}
          navigation={{
            nextEl: nextButton?.current as unknown as HTMLElement,
            prevEl: prevButton?.current as unknown as HTMLElement,
          }}
          onSlideChange={onSlideChange}
          onSwiper={setMainSwiper}
          loop
          slidesPerView={1}
          grabCursor
          updateOnWindowResize
        >
          {images.map((image, index) => (
            <SwiperSlide key={index} onClick={handleSlideClick}>
              <StyledMainGatsbyImage
                alt="Oleńki Park"
                image={getImage(image as any) as any}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          freeMode
          watchSlidesProgress
          updateOnWindowResize
          watchSlidesVisibility
          slidesPerView={3}
          spaceBetween={10}
          breakpoints={{
            1025: {
              spaceBetween: 16,
              watchSlidesVisibility: false,
            },
          }}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <StyledThumbsGatsbyImage
                alt="Oleńki Park"
                image={getImage(image as any) as any}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledSwipersWrapper>

      <StyledButtonsWrapper>
        <ArrowButton ref={prevButton} />
        <ArrowButton ref={nextButton} direction="right" />
      </StyledButtonsWrapper>

      <StyledCounterWrapper>
        <StyledCounter>
          {activeIndex < 9 ? `0${activeIndex + 1}` : activeIndex + 1}
          <StyledCounterBar $current={activeIndex + 1} $count={images.length} />
          {images.length < 10 ? `0${images.length}` : images.length}
        </StyledCounter>
      </StyledCounterWrapper>

      {isOpen && (
        <Lightbox
          mainSrc={lightboxImages[activeIndex]}
          nextSrc={lightboxImages[(activeIndex + 1) % lightboxImages.length]}
          prevSrc={
            lightboxImages[
              (activeIndex + lightboxImages.length - 1) % lightboxImages.length
            ]
          }
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            mainSwiper?.slideTo(
              ((activeIndex + lightboxImages.length - 1) %
                lightboxImages.length) +
                1
            )
          }
          onMoveNextRequest={() =>
            mainSwiper?.slideTo(((activeIndex + 1) % lightboxImages.length) + 1)
          }
        />
      )}
    </StyledWrapper>
  );
};

interface Props {
  images: ChildImageSharp[];
  lightboxImages: ChildImageSharp[];
  className?: string;
}

export default Gallery;
