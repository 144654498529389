import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Icon from '@iconify/react';
import arrowIcon from '@iconify-icons/bx/bx-arrow-back';

const StyledButton = styled.button`
  background: transparent;
  border: none;
  padding: 10px;
  outline: none;
  cursor: pointer;
`;

const StyledIcon = styled(Icon)<{ $right: boolean }>`
  font-size: 3.5rem;

  ${({ $right }) =>
    $right &&
    css`
      transform: rotate(180deg) !important;
    `}
`;

const ArrowButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ direction = 'left', className, onClick }, ref) => (
    <StyledButton
      aria-label={direction === 'left' ? 'Poprzednie' : 'Następne'}
      className={className}
      onClick={onClick}
      ref={ref}
    >
      <StyledIcon icon={arrowIcon} $right={direction === 'right'} />
    </StyledButton>
  )
);

interface Props {
  direction?: 'left' | 'right';
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export default ArrowButton;
