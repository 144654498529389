import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '@iconify/react';

const StyledWrapper = styled.div`
  width: 170px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledIcon = styled(Icon)`
  font-size: 8rem;
  color: ${({ theme }) => theme.primary};
`;

const StyledText = styled.p`
  text-align: center;
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontLight};

  span {
    font-weight: ${({ theme }) => theme.fontBold};
  }
`;

const FeatureBlock: FC<Props> = ({ icon, children, className, ...props }) => {
  return (
    <StyledWrapper className={className} {...props}>
      <StyledIcon icon={icon} />
      <StyledText>{children}</StyledText>
    </StyledWrapper>
  );
};

interface Props {
  icon: any;
  className?: string;
}

export default FeatureBlock;
